<template>
    <div>
        <!-- Loading -->
        <div v-if="!importSteps.length"
             class="d-flex align-items-center justify-content-center"
             style="min-height: 500px;">
            <Spinner size="100" line-fg-color="gray"/>
        </div>
        <!-- Not Loading -->
        <div v-else-if="currentImportStep">
            <input type="text"
                   id="scanModuleInput"
                   class="ui-hidden-accessible"
                   v-model="currentImportStep.data"
                   @change="loadScannedData()"
                   @focus="isScannerFocused = true"
                   @blur="isScannerFocused = false">
            <form id="add-item-form" @submit.prevent="addItem">
                <!-- Step Selection Buttons -->
                <div class="btn-group d-flex" role="group">
                    <button v-for="(step, index) in importSteps"
                            :key="`button${step.name}`"
                            @click="changeStep(index)"
                            type="button"
                            class="btn"
                            :class="index === currentImportStepIndex ? 'btn-secondary': 'btn-default'"
                            style="border-radius: 0; border: 1px solid #5a6169;">
                        <span v-show="step.complete"><i class="far fa-check-circle" /></span>
                        <span v-show="!step.complete"><i class="far fa-circle" /></span>
                        <span class="ml-2">{{ step.name }}</span>
                    </button>
                </div>
                <!-- Scan Module -->
                <div class="scan-module d-flex">
                    <div class="w-50 d-flex align-items-center justify-content-center"
                         style="cursor: pointer; overflow: hidden;"
                         @click="focusInput()">
                        <!-- Scan Module Display -->
                        <div class="scan-module-display w-75 text-center">
                            <div v-if="currentImportStep.error"
                                 class="d-flex flex-column align-items-center justify-content-center">
                                <i class="fal fa-times"></i>
                                {{ currentImportStep.error }}, click to refocus or enter data manually
                            </div>
                            <div v-else-if="isScannerFocused">
                                <Spinner size="100" line-fg-color="gray" style="margin-bottom: 10px"/>
                                <span>Waiting for {{ currentImportStep.name }} scan</span>
                            </div>
                            <div v-else class="d-flex flex-column align-items-center justify-content-center">
                                <i class="fal fa-eye-slash"></i>
                                <span class="mt-2">Focus lost and data cleared, click to refocus</span>
                            </div>
                        </div>
                    </div>
                    <!-- Scan Module Manual Inputs -->
                    <div class="w-50 d-flex justify-content-center flex-column"
                         style="border-left: 1px solid rgba(0, 0, 0, 0.125); overflow: hidden;">
                        <ProductImportItemsFormItem :validateField="validateField"
                                                    :fields="currentImportStepFields"/>
                    </div>
                </div>
                <!-- Manual Input Fields -->
                <div v-if="fields.find(field => !field.is_gs1 && !field.is_barcode)">
                    <hr style="margin: 0;">
                    <div style="padding: 20px">
                        <ProductImportItemsFormItem :validateField="validateField"
                                                    :fields="fields.filter(temp_field => !temp_field.is_gs1 && !temp_field.is_barcode && !temp_field.is_uuid)"/>
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
    import moment from 'moment';
    import _ from 'lodash';
    import validateUUID from 'uuid-validate';
    import {getProduct} from '../../services/ProductService';
    import {addItem, addItemFieldData, getItem} from '../../services/ItemService';
    import Cleave from 'vue-cleave-component';
    import PrintLabelsModal from '../../components/modals/PrintLabelsModal.vue';
    import Spinner from "vue-simple-spinner";
    import ProductImportItemsFormItem from "./ProductImportItemsFormItem";

    export default {
        name: 'ProductImportItemsForm',
        components: {
            PrintLabelsModal,
            Cleave,
            Spinner,
            ProductImportItemsFormItem
        },
        props: {
            autoImport: Boolean,
            itemCost: {
                type: Number,
                default: 0
            },
            importComplete: Boolean,
            importSessionFields: Array,
            importSessionTotalItems: Number,
            readyToAddItem: Boolean
        },
        data() {
            return {
                product: {},
                fields: [],
                currentImportStepIndex: 0,
                totalImportSteps: 0,
                isScannerFocused: true,
                importSteps: [],
                importSessionComplete: false,
                importedItems: 0,
                totalItems: 0
            };
        },
        mounted() {
            this.totalItems = this.importSessionTotalItems ? this.importSessionTotalItems : 0;
            this.generateImportSteps();
        },
        computed: {
            currentImportStep() {
                return this.importSteps[this.currentImportStepIndex];
            },
            currentImportStepFields() {
                if (this.currentImportStep.name === 'GS1') {
                    return this.fields.filter(field => field.is_gs1)
                } else if (this.currentImportStep.name === 'UUID') {
                    return this.fields.filter(field => field.is_uuid)
                } else {
                    return this.fields.filter(field => field.type_name === this.currentImportStep.name)
                }
            }
        },
        watch: {
            fields: {
                handler: function () {
                    this.importSteps.forEach(step => {
                        if (step.name === 'GS1') {
                            step.complete = !this.fields.some(field => field.is_gs1 && !field.complete)
                        } else if (step.name === 'UUID') {
                            step.complete = !this.fields.some(field => field.is_uuid && !field.complete)
                        } else {
                            step.complete = !this.fields.some(field => step.name === field.type_name && !field.complete)
                        }
                    })

                    // Emit that we're ready to add an item if all steps are complete
                    this.$emit('ready-to-add-item', this.fields.every(field => field.complete))
                },
                deep: true
            }
        },
        methods: {
            /**
             * Creates groups of fields that need to be scanned (GS1, Barcode, UUID, No-Name-Step-For-Manual-Inputs)
             */
            async generateImportSteps() {
                await this.getProductFields();
                this.importSteps = [];
                let counter = 0;

                // GS1
                if (this.fields.filter(field => field.is_gs1).length > 0) {
                    this.importSteps.push({
                        name: 'GS1',
                        data: '',
                        complete: !this.fields.some(field => field.is_gs1 && !field.complete),
                        error: ''
                    });
                    counter++;
                }
                // Barcode
                if (this.fields.some(field => field.is_barcode)) {
                    this.fields.filter(field => field.is_barcode).forEach(field => {
                        this.importSteps.push({
                            name: field.type_name,
                            data: '',
                            complete: field.complete,
                            error: ''
                        });
                        counter++;
                    });
                }
                // UUID (Always needed)
                this.importSteps.push({
                    name: 'UUID',
                    data: '',
                    complete: !this.fields.some(field => field.is_uuid && !field.complete),
                    error: '',
                });
                counter++;

                this.totalImportSteps = counter;
                this.$nextTick(() => {
                    this.nextStep();
                });
            },
            /**
             * Populates fields with import session data if available, else the product's fields
             */
            async getProductFields() {
                try {
                    const response = await getProduct(this.$route.params.id);
                    this.product = response.data;

                    if (this.importSessionFields && this.importSessionFields.length) {
                        this.fields = this.importSessionFields;
                    }
                    if (this.fields.length) {
                        this.fields.forEach(field => {
                            if (field.is_uuid) {
                                field.value = '';
                                field.complete = false;
                                field.error = '';
                            }
                        });
                    } else {
                        this.fields = this.product.fields.map(field => {
                            return {...field, value: '', complete: false, error: ''};
                        });
                        this.fields.push({
                            type_name: 'UUID',
                            type_tag: 'uuid',
                            complete: false,
                            is_uuid: true,
                            id: 'uuid',
                            value: '',
                            error: ''
                        })
                    }
                } catch (error) {
                    this.$router.push({name: 'product-list'});
                }
            },
            /**
             * Assigns currentImportStepIndex to the next, uncompleted step.
             */
            nextStep() {
                // If all fields are complete and the uuid is filled, add the item
                if (this.readyToAddItem && this.autoImport) {
                    this.addItem();
                } else {
                    // Move to the next incomplete step if there is one
                    if (!this.importSteps.every(step => step.complete)) {
                        this.changeStep(this.importSteps.findIndex(step => !step.complete));
                    }
                }
            },
            /**
             * Assigns currentImportStepIndex to the passed in argument.
             * @param {number} stepIndex - An index of importSteps
             */
            changeStep(stepIndex) {
                this.currentImportStepIndex = stepIndex;
                this.focusInput();
            },
            /**
             * Clears current input data and errors then select the current import step
             */
            focusInput() {
                this.currentImportStep.error = '';
                this.currentImportStep.data = '';
                this.$nextTick(() => {
                    // Check if the import session is complete to ensure we don't select non-existent input
                    if (this.importedItems !== this.totalItems) {
                        $('#scanModuleInput').focus();
                    }
                });
            },
            /**
             * Parent function for all scan functions (GS1, Barcode, UUID)
             */
            loadScannedData() {
                if (!this.currentImportStep.data) {
                    this.currentImportStep.error = 'Scanned data is empty'
                } else {
                    if (this.currentImportStep.name === 'GS1') {
                        this.loadGS1();
                    } else if (this.currentImportStep.name === 'UUID') {
                        this.loadUUID();
                    } else {
                        return this.loadBarcode()
                    }
                }
            },
            /**
             * Parses barcode string via user-specified indices
             */
            loadBarcode() {
                let field = this.fields.find(field => field.type_name === this.currentImportStep.name);
                let subData = this.currentImportStep.data.substring(field.barcode_start_index, field.barcode_end_index + 1);

                let formattedDate = moment(subData, field.date_format).format(field.date_format);
                if (field.is_date && formattedDate === 'Invalid date') {
                    field.complete = false;
                    this.currentImportStep.error = 'Barcode scanned does not match date format';
                } else {
                    field.value = formattedDate;
                    field.complete = true;
                    this.currentImportStep.error = '';
                    this.$nextTick(() => {
                        this.nextStep();
                    });
                }
            },
            /**
             * Parses GS1 XML for Nodes then compares data with fields to find needed field data
             */
            loadGS1() {
                // In the event the user pastes the GS1 xml from a rich text editor, un-enrich the quotes
                this.currentImportStep.data = this.currentImportStep.data.replace(/[“”]/g, '"');

                let parser = new DOMParser();
                const srcDOM = parser.parseFromString(this.currentImportStep.data, 'application/xml');
                const identifier = srcDOM.documentElement.getAttribute('dfi');

                if (identifier === 'GS1') {
                    let children = [...srcDOM.documentElement.childNodes];
                    let allFieldsFound = true;

                    let gs1Fields = this.fields.filter(field => field.is_gs1)
                    gs1Fields.forEach(field => {
                        let child = children.find(temp_child => temp_child.tagName === field.type_tag);
                        if (child) {
                            field.value = child.innerHTML;
                            if (field.is_date && !moment(child.innerHTML, field.date_format, true).isValid()) {
                                field.complete = false;
                                field.error = 'Scanned data does not match date format';
                            } else {
                                field.complete = true;
                                field.error = '';
                            }
                        } else {
                            field.value = '';
                            field.complete = false;
                            field.error = '';
                            allFieldsFound = false;
                        }
                    });

                    if (allFieldsFound) {
                        this.$nextTick(() => {
                            this.nextStep();
                        });
                    } else {
                        this.currentImportStep.error = 'Scanned barcode does not contain all required fields'
                    }
                } else {
                    this.currentImportStep.error = 'Scanned barcode is not GS1 format'
                }
            },
            /**
             * Checks UUID format and ensures that it does not currently exist in the back-end
             */
            async loadUUID() {
                const stepData = this.currentImportStep.data.toLowerCase();
                if (!validateUUID(stepData)) {
                    this.currentImportStep.error = 'Scanned barcode does not match UUID format';
                } else {
                    let field = this.fields.find(temp_field => temp_field.is_uuid)
                    // Check if UUID already exists in DB
                    await getItem(stepData).then(() => {
                        this.currentImportStep.error = 'Item with UUID already exists';
                    }).catch(error => {
                        if (error.response && error.response.status === 404) {
                            field.value = stepData;
                            field.complete = true;
                            field.error = '';
                            this.currentImportStep.error = '';
                            this.$nextTick(() => {
                                this.nextStep();
                            });
                        }
                    })
                }
            },
            /**
             * Validates fields to ensure that date formats are correct
             * @param {Object} field - A Product Field
             */
            async validateField(field) {
                // If the field is not null
                if (field.value) {
                    // If the field is a UUID
                    if (field.is_uuid) {
                        // Check that the data is in valid UUID format
                        if (validateUUID(field.value)) {
                            // Check if UUID already exists in DB
                            await getItem(field.value).then(() => {
                                field.error = 'Item with UUID already exists';
                                field.complete = false;
                            }).catch(error => {
                                if (error.response && error.response.status === 404) {
                                    field.complete = true;
                                    field.error = '';
                                }
                            })
                        } else {
                            field.error = 'Data does not match UUID format';
                        }
                    } else {
                        if (field.is_date) {
                            if (moment(field.value, field.date_format, true).isValid()) {
                                field.error = '';
                            } else {
                                field.error = 'Incorrect date format';
                            }
                        } else {
                            field.error = '';
                        }
                    }
                } else {
                    field.error = 'Please enter a value for this field';
                }

                field.complete = !field.error
            },
            /**
             * Adds an item and it's correlating field data to the back-end
             */
            async addItem() {
                if (this.readyToAddItem && this.importedItems < this.totalItems) {
                    try {
                        let uuidField = this.fields.find(field => field.is_uuid);
                        if (uuidField && this.product && this.product.id) {
                            // Add item
                            const response = await addItem({
                                uuid: uuidField.value.toLowerCase(),
                                cost: this.itemCost,
                                product: this.product.id
                            });

                            // Remove UUID field and format all dates to be in YYYY-MM-DD format
                            let nonUUIDFields = _.cloneDeep(this.fields);
                            nonUUIDFields = nonUUIDFields.filter(field => !field.is_uuid);
                            nonUUIDFields.forEach(temp_field => {
                                if (temp_field.is_date) {
                                    temp_field.value = moment(temp_field.value, temp_field.date_format).format('YYYY-MM-DD');
                                }
                            })

                            // Add item field data
                            await addItemFieldData(nonUUIDFields, response.data.id);

                            this.$emit('add-item', this.fields);
                            this.$emit('ready-to-add-item', false);
                            this.importedItems++;
                            await this.generateImportSteps();
                        } else {
                            this.currentImportStep.error = 'An error has occurred';
                        }
                    } catch (error) {
                        if (error.response) {
                            this.currentImportStep.error = 'An error has occurred';
                        }
                    }
                } else {
                    this.currentImportStep.error = 'Attempted to submit incomplete form';
                }
            }
        }
    };
</script>

<style scoped>
    .scan-module {
        min-height: 500px
    }

    .ui-hidden-accessible {
        position: absolute !important;
        height: 1px;
        width: 1px;
        overflow: hidden;
        clip: rect(1px, 1px, 1px, 1px);
    }

    .scan-module-display .fa-eye-slash {
        font-size: 100px;
    }

    .scan-module-display .fa-times {
        font-size: 150px;
    }
</style>
