<template>
    <div class="container my-4">
        <div class="card" v-if="importSession">
            <div class="card-header d-flex">
                <b>{{ importSession.productName }}</b>
                <span class="ml-auto">Imported: {{ totalProgress }}</span>
            </div>

            <ProgressBar size="big" :val="progressBarValue" :bar-color="progressBarColor"/>

            <div class="card-body" style="padding: 0;">
                <p v-if="importComplete" style="padding: 20px;">
                    Import session complete. Return to the
                    <router-link :to="{ name: 'product-list'}">products page.</router-link>
                </p>
                <ProductImportItemsForm v-else
                                        @add-item="addedItem"
                                        :readyToAddItem="readyToAddItem"
                                        @ready-to-add-item="readyToAddItem = $event"
                                        :itemCost="importSession.itemCost"
                                        :importSessionFields="importSession.fields"
                                        :importSessionTotalItems="Number(importSession.importAmount)"
                                        :autoImport="autoImport"
                                        :importComplete="importComplete"/>
            </div>

            <div v-if="!importComplete" class="card-footer d-flex align-items-center">
                <div class="form-group custom-switch mb-0 mr-3">
                    <input type="checkbox"
                           class="custom-control-input"
                           id="auto-import"
                           v-model="autoImport"/>
                    <label for="auto-import" class="custom-control-label">Import automatically</label>
                </div>
                <button :disabled="!readyToAddItem"
                        type="submit"
                        form="add-item-form"
                        class="btn btn-primary btn-sm ml-auto"
                        style="background-color: #5a6169; color: white;">
                    Add item
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import ProgressBar from 'vue-simple-progress';
import ProductImportItemsForm from '../../components/forms/ProductImportItemsForm.vue';

export default {
    name: 'ProductImportItems',
    components: {
        ProgressBar,
        ProductImportItemsForm
    },
    data() {
        return {
            importSession: {
                totalScanned: 0
            },
            readyToAddItem: false,
            autoImport: true
        };
    },
    computed: {
        importComplete() {
            return this.importSession.totalScanned >= this.importSession.importAmount;
        },
        totalProgress() {
            return `${this.importSession.totalScanned} / ${this.importSession.importAmount}`;
        },
        progressBarValue() {
            return (this.importSession.totalScanned / this.importSession.importAmount) * 100;
        },
        progressBarColor() {
            return this.importComplete ? '#5cb85c' : '#5bc0de';
        }
    },
    beforeRouteLeave(to, from, next) {
        localStorage.removeItem('importSession');
        next();
    },
    created() {
        this.setImportData();
    },
    methods: {
        setImportData() {
            if (!localStorage.getItem('importSession'))
                return this.$router.push({ name: 'product-list' });
            // Create a temp import session to convert itemCost to a number
            let tempImportSession = JSON.parse(localStorage.getItem('importSession'));
            tempImportSession.itemCost = Number(tempImportSession.itemCost);
            this.importSession = tempImportSession;
        },
        addedItem(fields) {
            this.importSession.totalScanned++;
            this.importSession.fields = fields;
            localStorage.setItem('importSession', JSON.stringify(this.importSession));
        }
    }
};
</script>

<style scoped>
    .custom-control-input:checked ~ .custom-control-label::before {
        background-color: #5a6169;
    }
</style>
