<template>
    <div>
        <div class="form-group px-4"
             v-for="field in fields"
             :key="`input${field.id}`">
            <label :for="field.type_tag + ' Input'">
                {{ field.type_name }} {{ field.is_date ? `(${field.date_format})` : '' }}
            </label>
            <div class="input-group">
                <input :id="field.type_tag + 'Input'"
                       v-model="field.value"
                       @input="validateField(field)"
                       type="text"
                       class="form-control"
                       required>
                <div class="input-group-append" v-if="field.complete">
                    <span class="input-group-text"><i class="fas fa-check"/></span>
                </div>
                <div class="input-group-append" v-else-if="field.error">
                    <span class="input-group-text"><i class="fas fa-times"/></span>
                </div>
            </div>
            <small v-if="field.error" class="field-error">{{ field.error }}</small>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'ProductImportItemsFormItem',
        props: {
            fields: Array,
            validateField: Function
        }
    };
</script>

<style scoped>
    .field-error {
        color: red;
    }

    .input-group-text .fa-times {
        color: red;
    }

    .input-group-text .fa-check {
        color: green;
    }
</style>
